.title2 {
  font-size: 30px;
  border-bottom: 8px solid transparent;
  border-image: linear-gradient(to right, #ffffff 0%, #26a347 100%);
  border-image-slice: 1;
  width: fit-content;
}

.contact {
  margin-top: 3rem;
}

input[type="text"],
input[type="number"],
input[type="email"],
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 300px;
}

input[type="submit"] {
  width: 100%;
  background-color: #ffffff;
  color: rgb(56, 56, 56);
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:disabled,
input[type="submit"][disabled] {
  width: 100%;
  background-color: rgb(33, 195, 103);
  color: rgb(255, 255, 255);
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #a5a5a5;
}

div {
  border-radius: 5px;
}
