:root {
  --backgroundtheme: rgb(52, 52, 52);
  --greentheme: #26a347;
  --textcolortheme: #ffffff;

  background-color: var(--backgroundtheme);
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1.9rem;
  line-height: 1.6;
  color: var(--textcolortheme);
}

/* custom classes */
.container {
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 2rem;
  background-color: #26a347;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: 30px;
}

/* flexbox and grid */
#frontpageimage {
  width: 100%;
  display: block;
}
