.about-content {
  row-gap: 40px;
  font-size: 17px;
  padding-bottom: 40px;
  margin: 0px;
  padding: 0 7rem;
}

.title {
  font-size: 30px;
  border-bottom: 8px solid transparent;
  border-image: linear-gradient(to right, #ffffff 0%, #26a347 100%);
  border-image-slice: 1;
  width: fit-content;
  h3 {
    margin: 0px;
  }
}

@media screen and (min-width: 768px) {
  .about-content {
    text-align: left;
  }
}

@media screen and (min-width: 992px) {
  .about-content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 60px;
  }
}
